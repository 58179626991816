<input-number>
    <div class="mui-textfield">
        <input disabled={ state.disabled } placeholder={ state.placeholder } type="text" step={ state.step } onkeyup={ keyup } onblur={ onBlur} value={ state.value }>
        <label>{state.label}</label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.label = props.label;
                state.disabled = props.disabled;
                state.modelMap = props.modelMap;
                state.placeholder = props.placeholder;
                state.type = props.type;
                state.onCommit = props.onCommit;
                state.value = this.displayValue(props.modelValue);
            },

            onBeforeUpdate(props, state) {
                if (props.id !== state.id) {
                    state.label = props.label;
                    state.modelMap = props.modelMap;
                    state.onCommit = props.onCommit;
                    state.placeholder = props.placeholder;
                    state.type = props.type;
                }

                state.value = this.displayValue(props.modelValue);
                state.disabled = props.disabled;
            },

            displayValue(value) {
                if (!value)
                    return;

                var type = this.state.type;
                if (type === "money") {
                    return this.accounting.formatMoney(value, {
                        symbol : "",
                        format: "%v",
                        precision : this.formats.decimalPlaces,
                        thousand: this.formats.groupSeparator,
                        decimal : this.formats.decimalSeparator
                    });
                }

                if (type === "number") {
                    return this.accounting.formatNumber(value, {
                        precision : this.formats.decimalPlaces,
                        thousand: this.formats.groupSeparator,
                        decimal : this.formats.decimalSeparator
                    });
                }

                if (type === "int") {
                    return this.accounting.formatNumber(value, {
                        precision : 0,
                        thousand: "",
                        decimal : ""
                    });
                }
            },

            keyup(e) {
                if(e.keyCode === 13 || e.keyCode === 9)
                    this.commit(this.toType(e.target.value, this.state.type));
            },

            onBlur(e) {
                this.commit(this.toType(e.target.value, this.state.type));
            },

            commit(value) {
                if (!isNaN(value) && this.state.onCommit)
                    this.state.onCommit(value, this.state.modelMap);
            },

            toType(viewValue, type) {
                if (!viewValue)
                    return;

                return this.accounting.unformat(viewValue, this.formats.decimalSeparator, this.formats.groupSeparator);
            }
        }
    </script>
</input-number>