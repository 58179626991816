<input-date>
    <div class="mui-textfield">
        <input disabled={ state.disabled } type="text" placeholder={ state.inputFormat } onkeyup={ commit } value={ state.value }>
        <label>{state.label}</label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.label = props.label;
                state.disabled = props.disabled;
                state.modelMap = props.modelMap;
                state.onCommit = props.onCommit;
                state.inputFormat = this.formats.shortDate;
                state.value = props.modelValue ? this.moment(props.modelValue).format(state.inputFormat) : null;
            },

            onBeforeUpdate(props, state) {
                if (props.id !== state.id) {
                    state.label = props.label;
                    state.modelMap = props.modelMap;
                    state.onCommit = props.onCommit;
                }

                state.value = props.modelValue ? this.moment(props.modelValue).format(state.inputFormat) : null;
                state.disabled = props.disabled;
            },

            commit(e) {
                var value = e.target.value;
                var dateValue = this.moment(value, this.state.inputFormat, true);
                if ((value === "" || dateValue.isValid()) && this.state.onCommit)
                    this.state.onCommit(dateValue, this.state.modelMap);
            }
        }
    </script>
</input-date>