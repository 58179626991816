<input-list>
    <div class="mui-textfield">
        <input disabled={ state.disabled } placeholder={ state.placeholder } type="text" onkeyup={ commit } value={ state.value }>
        <label>{ state.label }</label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.label = props.label;
                state.disabled = props.disabled;
                state.modelMap = props.modelMap;
                state.onCommit = props.onCommit;
                state.value = this.toString(props.modelValue);
                state.placeholder = props.placeholder;
            },

            onBeforeUpdate(props, state) {
                if (props.id !== state.id) {
                    state.label = props.label;
                    state.modelMap = props.modelMap;
                    state.onCommit = props.onCommit;
                    state.placeholder = props.placeholder;
                }

                state.value = this.toString(props.modelValue);
                state.disabled = props.disabled;
            },

            toList(value) {
                return value ? value.split(",").map(s => s.trim()) : [];
            },

            toString(value) {
                return value ? value.toString() : "";
            },

            commit(e) {
                var value = e.target.value;
                if (this.state.onCommit)
                    this.state.onCommit(this.toList(value), this.state.modelMap);
            }
        }
    </script>
</input-list>