<new-project>
    <div class="form-dialog">
        <form class="mui-form">
            <div class="mui-row">
                <div class="mui-col-md-6">
                    <input-text label="Customer number" model-map="customerNumber" model-value={ state.model.customerNumber } on-commit={ bindValue }></input-text>
                </div>
                <div class="mui-col-md-6">
                    <input-text label="Project number" model-map="key" model-value={ state.model.key } on-commit={ bindValue }></input-text>
                </div>
            </div>
            <div class="mui-row">
                <div class="mui-col-md-12">
                    <input-text label="Name" model-map="name" model-value={ state.model.name } on-commit={ bindValue }></input-text>
                </div>
            </div>
            <div class="mui-row button-row">
               <button type="button" onclick={ create } class="mui-btn mui-btn--raised">Create</button>
               <button type="button" onclick={ closeDialog } class="mui-btn mui-btn--raised">Cancel</button>
            </div>
        </form>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.model = {};
                state.onCreate = props.onCreate;
                state.dialog = props.dialog;
            },

            bindValue(value, modelMap) {
                _.set(this.state.model, modelMap, value);
                this.update();
            },

            create() {
                var uri = this.apiUrls.projects;

                this.api.post(uri, this.state.model).then(id => {
                    if (id) {
                        if (this.state.onCreate)
                            this.state.onCreate(id);

                        this.alertify.notify("Project created");
                        this.closeDialog();
                    }
                });
            },

            closeDialog() {
                if (this.state.dialog)
                    this.state.dialog().close();
            }
        }
    </script>
</new-project>