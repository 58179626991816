<module-checkbox>
    <div class="module-checkbox-container">
        <div class="mui-checkbox module-checkbox">
            <label>
                <input type="checkbox" data-model-map={ "checked" } onchange={ onChange } checked={ state.model.checked }>
                { state.module }
            </label>
        </div>
        <div class="module-checkbox-settings">
            <div class="mui-checkbox module-checkbox-setting">
                <label>
                    <input type="checkbox" disabled={ disableSettings("documentsLimited") } data-model-map={ "documentsRadioValue" } data-radio-value="Limited" onchange={ onChange } checked={ state.model.documentsRadioValue === "Limited" }>
                Limited
                </label>
            </div>
            <div class="mui-checkbox module-checkbox-setting">
                <label>
                <input type="checkbox" disabled={ disableSettings("documentsOpen") } data-model-map={ "documentsRadioValue" } data-radio-value="Open" onchange={ onChange } checked={ state.model.documentsRadioValue === "Open" }>
                Open
                </label>
            </div>
            <div class="mui-checkbox module-checkbox-setting mui--divider-right">
                <label>
                <input type="checkbox" disabled={ disableSettings("documentsAccess") } data-model-map={ "documentsRadioValue" } data-radio-value="Access" onchange={ onChange } checked={ state.model.documentsRadioValue === "Access" }>
                Access
                </label>
            </div>
            <div class="mui-checkbox module-checkbox-setting">
                <label>
                    <input type="checkbox" disabled={ disableSettings("usersLimited") } name={ state.radioGroupName } data-model-map={ "usersRadioValue" } data-radio-value="Limited" onchange={ onChange } checked={ state.model.usersRadioValue === "Limited" }>
                Limited
                </label>
            </div>
            <div class="mui-checkbox module-checkbox-setting">
                <label>
                <input type="checkbox" disabled={ disableSettings("usersOpen") } data-model-map={ "usersRadioValue" } data-radio-value="Open" onchange={ onChange } checked={ state.model.usersRadioValue === "Open" }>
                Open
                </label>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.onChange = props.onChange;
                state.module = props.module;
                state.model = props.initialModelState;
                state.typeCombos = props.typeCombos;
            },

            onMounted() {
                this.setDefaultValues();
            },

            setDefaultValues() {
                var preferredDefaultOrder = ["Limited", "Open", "Access"];

                var defaultDocumentsValue = this.getDefaultValue("documents", preferredDefaultOrder);
                this.bindValue(defaultDocumentsValue, "documentsRadioValue");

                if (!defaultDocumentsValue)
                    this.bindValue(this.getDefaultValue("Users", preferredDefaultOrder), "usersRadioValue");
            },

            getDefaultValue(type, preferredDefaultOrder) {
                for (var i=0; i < preferredDefaultOrder.length; i++) {
                    var restrictionType = preferredDefaultOrder[i];
                    if (this.validCombo(type + restrictionType))
                        return restrictionType;
                }
            },

            bindValue(value, modelMap) {
                _.set(this.state.model, modelMap, value);
                this.update();

                if (this.state.onChange)
                    this.state.onChange(this.state.module, this.state.model);
            },

            disableSettings(combo) {
                return !this.state.model.checked || !this.validCombo(combo);
            },

            validCombo(combo) {
                return this.state.typeCombos.some(type => {
                    return (type.type + type.restrictionType).toLowerCase() === combo.toLowerCase();
                });
            },

            onChange(e) {
                var modelMap = e.target.dataset.modelMap;
                var value = e.target.dataset.radioValue ? e.target.dataset.radioValue : e.target.checked;
                this.bindValue(value, modelMap);
            }
        }
    </script>
</module-checkbox>