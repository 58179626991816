<tabs-bar>
    <div class="tabs-bar" id={ state.id }>
        <div class="tabs">
            <div class="tab" each={ tab in state.tabs } data-name={ tab } onclick={ selectTab }>
                <div class="tab-title">{ tab }</div>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                this.onSelected = props.onSelected;
                state.tabs = props.tabs;
                state.id = props.name + "_tabs";
            },

            onMounted(props, state) {
                if (props.initialSelection)
                    this.setInitialSelection(props.initialSelection);
            },

            setInitialSelection(tab) {
                var preventPropagation = tab.indexOf("$") !== -1;
                if (preventPropagation)
                    tab = tab.replace("$", "");

                var tabs = [].slice.call(this.getRootElement().getElementsByClassName('tab'));
                var tab = tabs.filter(function(element) { return element.dataset.name === tab; });

                if (tab.length === 1)
                    this.selectTab(tab[0], preventPropagation);
            },

            selectTab(e, preventPropagation) {
                var element = e.target ? e.target : e;
                var tabElement = element.getElementByClassInAncestry('tab');
                var tabName = tabElement.dataset.name;

                var result = [].slice.call(this.getRootElement().getElementsByClassName('selected'));
                result.forEach(function(el) { el.classList.remove('selected'); });
                tabElement.classList.add('selected');

                if (!preventPropagation)
                    this.onSelected(tabName);
            },

            getRootElement() {
                return document.getElementById(this.state.id);
            }
        }
    </script>
</tabs-bar>