<form-builder>
    <form class="mui-form">
        <div if={ state.title } class="form-title">
            { state.title }
        </div>
        <div if={ state.show } class="mui-row" each={ fieldRow in state.config.fieldRows }>
            <div each={ field in fieldRow.fields }
                class={ fieldRow.rowClass }
                id={ field.id }
                is={ field.riotTag }
                label={ field.label }
                model-map={ field.modelMap }
                type={ field.type }
                placeholder={ field.placeholder }
                lookup={ field.lookup }
                model-value={ getModelValue(field.modelMap, field.valueGetter) }
                on-commit={ onfieldCommit }
                disabled={ field.readOnly || state.readOnly || !state.editable }></div>
        </div>
        <button if={ !state.readOnly && !state.continuousCommit } disabled={ !state.editable } type="button" onclick={ commit } class="mui-btn mui-btn--raised">Save</button>
    </form>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.model = props.model;
                state.config = props.config;
                state.onCommit = props.onCommit;
                state.readOnly = props.readOnly;
                state.editable = _.isBoolean(props.editable) ? props.editable : true;
                state.continuousCommit = props.continuousCommit;
                state.tags = [];
                state.title = props.title;
                state.show = true;

                this.expandConfig();
            },

            onBeforeUpdate(props, state) {
                if (props.model.id !== state.model.id || props.model.version !== state.model.version) {
                    state.model = props.model;
                    state.config = props.config;
                    state.editable = _.isBoolean(props.editable) ? props.editable : true;
                    this.expandConfig();
                }
            },

            expandConfig() {
                var config = this.state.config;
                config.fieldRows.forEach(fieldRow => {
                    fieldRow.rowClass = "mui-col-md-" + (12 / fieldRow.fields.length);
                    fieldRow.fields.forEach(field => {
                        field.id = config.name + "_" + field.label;
                        field.riotTag = this.getRiotTag(field.type);
                    });
                });
            },

            getRiotTag(type) {
                if (type === "text" || type === "string" || !type)
                    return "input-text";
                if (type === "textarea")
                    return "input-text-area";
                else if (type === "number" || type === "money" || type === "int")
                    return "input-number";
                else if (type === "date")
                    return "input-date";
                else if (type === "bool")
                    return "input-bool";
                else if (type === "list")
                    return "input-list";
                else if (type === "typeahead")
                    return "typeahead";
            },

            getModelValue(modelMap, valueGetter) {
                return valueGetter ? valueGetter(this.state.model) : _.get(this.state.model, modelMap);
            },

            onfieldCommit(value, modelMap) {
                _.set(this.state.model, modelMap, value);
                this.update();

                if (this.state.continuousCommit)
                    this.commit();
            },

            commit() {
                if (this.state.onCommit)
                    this.state.onCommit(this.state.model);
            },
        }
    </script>
</form-builder>