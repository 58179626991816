<input-text-area>
    <div class="mui-textfield">
        <textarea disabled={ state.disabled } placeholder={ state.placeholder } onkeyup={ commit } value={ state.value } rows={ state.rows }></textarea>
        <label>{ state.label }</label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.label = props.label;
                state.disabled = props.disabled;
                state.modelMap = props.modelMap;
                state.onCommit = props.onCommit;
                state.value = props.modelValue;
                state.placeholder = props.placeholder;
                state.rows = props.rows;
            },

            onBeforeUpdate(props, state) {
                if (props.id !== state.id) {
                    state.label = props.label;
                    state.modelMap = props.modelMap;
                    state.onCommit = props.onCommit;
                    state.placeholder = props.placeholder;
                    state.rows = props.rows;
                }

                state.value = props.modelValue;
                state.disabled = props.disabled;
            },

            commit(e) {
                var value = e.target.value;
                if (this.state.onCommit)
                    this.state.onCommit(value, this.state.modelMap);
            }
        }
    </script>
</input-text-area>