<new-project-part>
    <div class="form-dialog">
        <div class="mui-row top-text">
            <p>Add parts to existing license. When creating for eye-share Workflow, you select which modules you want and what types, the appropriate parts will automatically be selected.</p>
        </div>
        <form class="mui-form new-license-group-form">
            <div class="mui-row">
                <div class="mui-col-md-12">
                    <typeahead  name="Product" source={ fetchProducts } on-commit={ setProduct } dropdown-parent={ ".ajs-modal" }></typeahead>
                </div>
            </div>
            <div if={ state.moduleConfiguration } class="modules">
                <div class="license-types-header mui-row">
                    <div class="mui-col-md-4"></div>
                    <div class="mui-col-md-4 license-type">Documents</div>
                    <div class="mui-col-md-4 license-type">Users</div>
                </div>
                <div class="mui-row" each={ m in getModuleCheckboxKeys() }>
                    <div class="mui-col-md-12">
                        <module-checkbox module={ m } type-combos={ state.moduleCheckboxModel[m].typeCombos } initial-model-state={ state.moduleCheckboxModel[m].model } on-change={ moduleCheckboxChanged }></module-checkbox>
                    </div>
                </div>
            </div>
            <div if={ state.components } class="components">
                <div class="components-checkboxes">
                    <div class="mui-checkbox component-checkbox" each={ component in state.components }>
                        <label>
                            <input type="checkbox" data-component={ component.id } onchange={ componentCheckboxChanged }>
                        { component.displayValue }
                        </label>
                    </div>
                </div>
            </div>
            <div class="mui-row button-row">
               <button type="button" onclick={ create } class="mui-btn mui-btn--raised">Add</button>
               <button type="button" onclick={ closeDialog } class="mui-btn mui-btn--raised">Cancel</button>
            </div>
        </form>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.model = {
                    customerProject: props.customerProject,
                    tenant: {}
                };
                state.componentCheckboxValues = {};
                state.moduleCheckboxModel = {};
                state.onCreate = props.onCreate;
                state.dialog = props.dialog;
            },

            getModuleCheckboxKeys() {
                return Object.keys(this.state.moduleCheckboxModel);
            },

            buildModuleConfiguration(configuration) {
                var moduleCheckboxModel = this.buildModuleCheckboxModel(configuration)
                this.update({ moduleConfiguration: configuration, moduleCheckboxModel: moduleCheckboxModel });
            },

            buildModuleCheckboxModel(configuration) {
                var moduleCheckboxModel = {};
                var moduleState= {};
                _.forEach(configuration, (combos, module) => {
                    moduleState[module] = {
                        model: { checked: false },
                        typeCombos: combos
                    };
                });

                return moduleState;
            },

            moduleCheckboxChanged(module, model) {
                this.state.moduleCheckboxModel[module].model = model;
            },

            componentCheckboxChanged(e) {
                var componentId = e.target.dataset.component;
                var checked = e.target.checked;
                this.state.componentCheckboxValues[componentId] = checked;
            },

            fetchProducts() {
                return this.api.get(`${this.apiUrls.licenses}/project/${this.state.model.customerProject.id}`).then(licenses => {
                    return licenses.map(l => l.product);
                });
            },

            setProduct(product) {
                var model = this.state.model;
                model.product = product;
                if (this.isESWorkflowProduct(product)) {
                    this.api.get(`${this.apiUrls.lookup}/products/${product.id}/modules/configuration`).then(configuration => {
                        this.state.components = null;
                        this.buildModuleConfiguration(configuration);
                    });
                }
                else {
                    this.api.get(`${this.apiUrls.lookup}/products/${product.id}/components`).then(components => {
                        this.update({ components: components, moduleConfiguration: null });
                    });
                }
            },

            updateModel(model) {
                Object.assign(this.state.model, model);
            },

            bindValue(value, modelMap) {
                _.set(this.state.license, modelMap, value);
                this.update();
            },

            create() {
                var model = this.state.model;

                if (!model.tenant.id && !model.tenant.client)
                    model.tenant = null;

                if (this.state.moduleConfiguration) {
                    var moduleTypeMapping = {};
                    _.forEach(this.state.moduleCheckboxModel, function(val, key) {
                        var types = [];
                        var model = val.model;
                        if (!model.checked)
                            return;

                        types.push({ Type: "Documents", RestrictionType: model.documentsRadioValue })
                        types.push({ Type: "Users", RestrictionType: model.usersRadioValue })

                        moduleTypeMapping[key] = types;
                    });

                    model.moduleTypeMapping = moduleTypeMapping;
                }

                if (this.state.components) {
                    model.componentIds = this.state.components.filter(c => this.state.componentCheckboxValues[c.id]).map(c => c.id);
                }

                var uri = this.apiUrls.licenses + "/create";
                this.api.post(uri, model).then(() => {
                    if (this.state.onCreate)
                        this.state.onCreate();

                    this.closeDialog();
                });
            },

            isESWorkflowProduct(product) {
                return product.key === "eye-share Workflow";
            },

            closeDialog() {
                if (this.state.dialog)
                    this.state.dialog().close();
            }
        }
    </script>
</new-project-part>