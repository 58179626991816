<reports>
    <div class="form-dialog">
        <form class="mui-form">
            <div class="mui-row">
                <div class="mui-col-md-8">
                    <typeahead  name="Report" source={ fetchReportNames } on-commit={ setReportName } dropdown-parent={ ".ajs-modal" }></typeahead>
                </div>
                <div class="mui-col-md-4">
                    <typeahead  name="Format" source={ fetchFormats } on-commit={ setFormat } dropdown-parent={ ".ajs-modal" }></typeahead>
                </div>
            </div>
            <div id="ReportDefinitionsForm"></div>
            <div class="mui-row button-row">
               <button type="button" onclick={ download } class="mui-btn mui-btn--raised">Download</button>
               <button type="button" onclick={ closeDialog } class="mui-btn mui-btn--raised">Cancel</button>
            </div>
        </form>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.model = {};
                state.dialog = props.dialog;
            },

            bindValue(value, modelMap) {
                _.set(this.state.model, modelMap, value);
                this.update();
            },

            fetchReportNames() {
                var me = this;
                return this.api.get(`${this.apiUrls.reports}/definitions`).then(result => {
                    me.state.reportDefinitions = result;
                    return result.map(r => r.name);
                });
            },

            setReportName(reportName) {
                this.state.model.ReportName = reportName;
                var report = this.state.reportDefinitions.filter(r => r.name == reportName)[0];
                this.buildParametersForm(report.parameterDefinitions);
            },

            fetchFormats() {
                return $.when(["csv", "json"]);
            },

            setFormat(format) {
                this.state.format = format;
            },

            updateModel(model) {
                this.state.model = model;
            },

            buildParametersForm(definitions) {
                this.riot.unmount('#ReportDefinitionsForm', true);
                var config = { name: "parameters", fieldRows: [] };
                var fieldsConfig = [];

                _.forEach(definitions, (value, key) => {
                        var modelMap = "Parameters." + key;
                        var fieldConfig = { label: key, modelMap: modelMap, type: value };
                        fieldsConfig.push(fieldConfig);
                    });

                if (fieldsConfig.length == 0)
                    return;

                while (fieldsConfig.length)
                    config.fieldRows.push({ fields: fieldsConfig.splice(0, 2)});

                this.state.reportDefinitionsForm = this.riot.mount('#ReportDefinitionsForm', {
                                    continuousCommit: true,
                                    config: config,
                                    model: this.state.model,
                                    onCommit: this.updateModel
                                }, 'form-builder');
            },

            download() {
                var uri = `${this.apiUrls.reports}/download/${this.state.format}`;
                this.api.download(uri, this.state.model, this.state.model.ReportName);
            },

            closeDialog() {
                if (this.state.dialog)
                    this.state.dialog().close();
            }
        }
    </script>
</reports>