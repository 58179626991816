<typeahead>
    <div class="mui-select">
        <select disabled={ state.disabled } class="typeahead" id={ state.id }>
        </select>
        <label>{ state.name }</label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.onCommit = props.onCommit;
                state.modelMap = props.modelMap;
                state.lookup = props.lookup;
                state.value = props.modelValue;
                state.name = props.label ? props.label : props.name;
                state.id = props.name + "_typeahead";
                state.source = props.source;
                state.disabled = props.disabled;
                state.dropdownParent = props.dropdownParent;
                this.eventEmitter.on('typeahead_refreshSource', this.refreshSource);
                this.fetchData();
            },

            onUnmounted(props, state) {
                this.eventEmitter.off('typeahead_refreshSource', this.refreshSource);
            },

            onBeforeUpdate(props, state) {
                if (props.modelValue !== state.modelValue) {
                    state.modelValue = props.modelValue;
                    this.selectItem(state.modelValue);
                }

                state.disabled = props.disabled;
            },

            refreshSource(name) {
                if (name === this.state.name)
                    this.fetchData();
            },

            fetchData() {
                var me = this;
                var source;
                if (typeof this.state.source === "function") {
                    source = this.state.source();
                }
                else if (this.state.lookup !== "") {
                    source = this.api.lookup(this.state.lookup);
                }
                else {
                    this.alertify.warning(`Source or lookup for typeahead {${state.id}} not configured`);
                    return;
                }

                me.state.select2Ready = source.then(data => {
                    me.update({ data: data });
                    return $(document).ready(() => {
                        var select2Config = {
                            data: data.map(toSelect2Format)
                        };
                        if (me.state.dropdownParent)
                            select2Config.dropdownParent =  $(me.state.dropdownParent);

                        me.state.typeahead = $('#'+ me.state.id);
                        me.state.typeahead.select2(select2Config);
                        me.state.typeahead.on('select2:select', me.onSelect);
                        if (me.state.modelValue)
                            me.selectItem(me.state.modelValue);
                        else
                            me.selectItem(data[0]);
                    });
                });
            },

            selectItem(item) {
                $.when(this.state.select2Ready).then(() => {
                    var selectData = toSelect2Format(item);;
                    this.state.typeahead.val(selectData.id).trigger('change');
                    this.itemChanged(item);
                });
            },

            onSelect(e) {
                var selectData = e.params.data;
                var item = this.state.data.filter(item => item.id === selectData.id || item === selectData.id)[0];
                this.itemChanged(item);
            },

            itemChanged(item) {
                this.state.selectedItem = item;
                if (this.state.onCommit)
                    this.state.onCommit(item, this.state.modelMap);
            },
        }

        function toSelect2Format(data) {
            if (!data)
                return {};

            var isObject = typeof data === "object";
            return {
                id: isObject ? data.id : data,
                text: isObject ? (data.displayValue ? data.displayValue : data.key) : data,
            };
        }
    </script>
</typeahead>