(function () {
    "use strict";

    function getElementByClassInAncestryInternal(element, target, i) {
        var recursionLimit = 10;
        if (typeof target !== 'string')
            throw new Error("The 'target' argument must be a string!");
        if (!element.classList || !element.parentElement)
            throw new Error("Possibly wrong object type or ancestry is exhausted without result.");
        if (i >= recursionLimit)
            throw new Error("Could not find element within recursion limit!");

        if (element.classList.contains(target))
            return element;

        return getElementByClassInAncestryInternal(element.parentElement, target, ++i);
    }

    // It's imperative that these properties are non-enumerable!
    // Otherwise iterating over any object will return these properties, which we definitely don't want!
    Object.defineProperties(Object.prototype, {

        getElementByClassInAncestry: {
            enumerable: false,
            writable: true,
            configurable: true,
            value: function (target) {
                return getElementByClassInAncestryInternal(this, target, 0);
            }
        }
    });
}());


