import * as Msal from "@azure/msal-browser";

// https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-overview
const msalConfig = {
    auth: {
        // The azure app client id for the 'client' side, the server / api is configured to use the azure app client id for the 'api' part.
        clientId: 'ce9638b2-adc0-49b5-9687-688fe554906a',
        authority: 'https://login.microsoftonline.com/fa5c22d4-272c-4083-890a-4ffdc39a5571/'
    },
    // The common resource scopes that binds the two instances of azure apps.
    resourceScopes: ["api://afa15700-cdf9-496c-b426-8b41813cf146/access_as_user"]
};
const msalInstance = new Msal.PublicClientApplication(msalConfig);

export default {
    /**
     * Set the current users role.
     * @return {promise} Empty promise
     */
    setRole(api) {
        return api.get("api/user/role").then(role => this.role = role);
    },

    /**
     * Has read privileges.
     * @return {bool}
     */
    canRead() {
        return this.role === "Reader" || this.role === "Editor";
    },

    /**
     * Has edit privileges.
     * @return {bool}
     */
    canEdit() {
        return this.role === "Editor";
    },

    /**
     * Acquire token silently with redirect handle.
     * https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
     * @return {promise} Access token
     */
    acquireToken() {
        return msalInstance.handleRedirectPromise().then((redirectResponse) => {
            if (redirectResponse !== null) {
                // Acquire token silent success
                return redirectResponse.accessToken;
            }
            else {
                // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
                const account = msalInstance.getAllAccounts()[0];
                const accessTokenRequest = {
                    scopes: msalConfig.resourceScopes,
                    account: account,
                };

                return msalInstance
                    .acquireTokenSilent(accessTokenRequest)
                    .then(function (accessTokenResponse) {
                        // Acquire token silent success
                        return accessTokenResponse.accessToken;
                    })
                    .catch(function (error) {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        return msalInstance.acquireTokenRedirect(accessTokenRequest);
                    });
            }
        });
    }
};