<evaluations-view>
    <div class="view-evaluations">
        <div class="view-grid">
            <div class="view-col mui--divider-right">
                <grid-js config={ state.notificationGridConfig } source={ fetchNotifications } on-selected={ notificationSelected }></grid-js>
            </div>
            <div class="view-col">
                <grid-js config={ state.evaluationGridConfig } lazy-source={ true } source={ fetchEvaluations }></grid-js>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.notificationGridConfig = {
                    name: "notification",
                    columns: [
                        { name: "Project", modelMap: "project.displayValue", width: "200px" },
                        { name: "License", modelMap: "license.key", width: "200px" },
                        { name: "Notification", modelMap: "notification", width: "100px" }
                    ]
                };
                state.evaluationGridConfig = {
                    name: "evaluation",
                    columns: [
                        { name: "Rule", modelMap: "rule.key", width: "200px" },
                        { name: "Status", modelMap: "status", width: "75px" },
                        { name: "Description", modelMap: "description", width: "200px" },
                        { name: "Module", modelMap: "module", width: "125px" },
                        { name: "Result", modelMap: "result", width: "100px" },
                        { name: "Constraints", modelMap: "constraintsRenderValue", width: "200px" },
                        { name: "Evaluated", modelMap: "evaluationDate", type: "date", width: "150px" },
                        { name: "Expires", modelMap: "expires", type: "date", width: "150px" },
                    ]
                };
            },

            fetchNotifications() {
                return this.api.get(`${this.apiUrls.licenses}/notifications`);
            },

            notificationSelected(notification) {
                this.update({ selectedNotification: notification });
                this.eventEmitter.emit('grid_refreshSource', this.state.evaluationGridConfig.name);
            },

            fetchEvaluations() {
                if (!this.state.selectedNotification)
                    return $.when([]);

                var url = this.apiUrls.licenses + "/evaluations/" + this.state.selectedNotification.project.id + "/" + this.state.selectedNotification.license.id;
                return this.api.get(url);
            },
        }
    </script>
</evaluations-view>