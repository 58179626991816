// css
import "muicss/dist/css/mui.min.css";
import "select2/dist/css/select2.min.css";
import "material-design-iconic-font/dist/css/material-design-iconic-font.min.css";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/default.min.css";
import "gridjs/dist/theme/mermaid.css";

// Libraries
import * as riot from 'riot';
import { Route, Router } from '@riotjs/route';
import { Grid, html  } from "gridjs";
import * as $ from 'jquery';
import 'select2';
import eventEmitter from 'events';
import _ from 'lodash';
import moment from 'moment';
import alertify from 'alertifyjs';
import accounting from 'accounting-js';

// Import services
import auth from './services/auth.js';
import api from './services/api.js';
import shared from './services/shared.js';

// Import extensions
import './extensions/Object.prototype.js';

// Common
import app from './riot/app.riot';
import gridjs from './riot/common/grid.js.riot';
import formBuilder from './riot/common/form.builder.riot';
import typeahead from './riot/common/typeahead.riot';
import inputText from './riot/common/input.text.riot';
import inputTextArea from './riot/common/input.textarea.riot';
import inputDate from './riot/common/input.date.riot';
import inputNumber from './riot/common/input.number.riot';
import inputBool from './riot/common/input.bool.riot';
import inputList from './riot/common/input.list.riot';
import tabsBar from './riot/common/tabs.bar.riot';
import moduleCheckbox from './riot/common/module.checkbox.riot';
import reports from './riot/common/reports.riot';

// Project
import projectDocument from './riot/project/project.document.riot';
import newProject from './riot/project/project.new.riot';
import newProjectPart from './riot/project/project.part.new.riot';

// License
import licenseDocument from './riot/license/license.document.riot';
import newLicense from './riot/license/license.new.riot';
import newLicensePart from './riot/license/license.part.new.riot';

// Views
import evaluationsView from './riot/views/evaluations.view.riot';
import projectsView from './riot/views/projects.view.riot';
import licensesView from './riot/views/licenses.view.riot';

// Import styling
import './styles/app.less';
import './styles/scrollbar.less';
import './styles/licenses.less';
import './styles/projects.less';
import './styles/evaluations.less';
import './styles/grid.less';
import './styles/mui.overrides.less';

// Jquery hack
window.$ = $;

// Common
riot.register('router', Router);
riot.register('route', Route);
riot.register('app', app);
riot.register('grid-js', gridjs);
riot.register('form-builder', formBuilder);
riot.register('tabs-bar', tabsBar);
riot.register('typeahead', typeahead);
riot.register('input-text', inputText);
riot.register('input-text-area', inputTextArea);
riot.register('input-date', inputDate);
riot.register('input-number', inputNumber);
riot.register('input-bool', inputBool);
riot.register('input-list', inputList);
riot.register('module-checkbox', moduleCheckbox);
riot.register('reports', reports);

// Project
riot.register('project-document', projectDocument);
riot.register('new-project', newProject);
riot.register('new-project-part', newProjectPart);

// License
riot.register('license-document', licenseDocument);
riot.register('new-license', newLicense);
riot.register('new-license-part', newLicensePart);

// Register views
riot.register('evaluations-view', evaluationsView);
riot.register('projects-view', projectsView);
riot.register('licenses-view', licensesView);

// https://nodejs.org/api/events.html
var ee = new eventEmitter();

// Set auth instance for api
var apiUrls = {
    licenses: 'api/licenses',
    projects: 'api/projects',
    lookup: 'api/lookup',
    user: 'api/user',
    components: 'api/components',
    serviceNow: 'api/servicenow',
    reports: 'api/reports'
};
api.init(auth, apiUrls);

// Accounting settings
var formats = {
    groupSeparator: " ",
    decimalSeparator: ",",
    decimalPlaces: 2,
    date:  "DD.MM.YYYY HH:mm:ss",
    shortDate:  "DD.MM.YYYY"
};

// Each riot component will get these registrations
riot.install(component => {
    component.riot = riot;
    component.gridjs = Grid;
    component.html = html;
    component.accounting = accounting;
    component.formats = formats;
    component.auth = auth;
    component.api = api;
    component.shared = shared;
    component.eventEmitter = ee;
    component.moment = moment;
    component.alertify = alertify;
    component.apiUrls = apiUrls;
    component.guidEmpty = function() {
        return "00000000-0000-0000-0000-000000000000";
    };
});

// Login and then mount components
auth.acquireToken().then(() => auth.setRole(api)).then(() => riot.mount('app'));

