<app>
    <router base={ state.base }>
        <div class="mui-appbar app-bar" id="app-bar">
            <div class='app-logo'>
                <img src="assets/images/eye-share-logo-square-only.png">
                <p>eye-share license portal</p>
            </div>
            <div class='nav-bar'>
                <div each={ link in state.links } class="nav-item { isLinkSelected(link) ? 'selected' : '' }">
                    <a href={ link.url }>{ link.name }</a>
                </div>
            </div>
        </div>
        <route path="/projects" onMounted={ onRouteMounted }>
            <projects-view></projects-view>
        </route>
        <route path="/licenses" onMounted={ onRouteMounted }>
            <licenses-view></licenses-view>
        </route>
        <route path="/evaluations" onMounted={ onRouteMounted }>
            <evaluations-view></evaluations-view>
        </route>
    </router>

    <script>
        const loc = window.location;
        export default {
            onBeforeMount(props, state) {
                state.base = `${loc.protocol}//${loc.host}${loc.pathname ? loc.pathname : '' }#`
                state.links = [
                    { name: "Projects", url: "#/projects" },
                    { name: "Licenses", url: "#/licenses" },
                    { name: "Evaluations", url: "#/evaluations" },
                ];

                // Default to projects view
                if (loc.hash === "")
                    loc.hash = "#/projects";
            },

            onRouteMounted() {
                this.update();
            },

            isLinkSelected(link) {
                return link.url === loc.hash;
            },
        }
    </script>
</app>