export default {
    /**
     * Build constraints form config
     */
    buildConstraintsFormConfig(licensePart, api, apiUrls, strictDisabled) {
        var uri = apiUrls.lookup + "/components/" + licensePart.id + "/constraints";
        return api.get(uri).then(constraintDefinitions => {
            var config = { name: "constraints", fieldRows: [] };
            var fieldsConfig = [];

            _.forEach(constraintDefinitions, (value, key) => {
                var modelMap = "constraints." + key;
                var fieldConfig = { label: key, modelMap: modelMap, type: value };
                fieldsConfig.push(fieldConfig);
            });

            if (licensePart.type.restrictionType === "Limited")
                fieldsConfig.push({ label: "Strict rule enforcement", "modelMap": "strictRuleEnforcement", type: "bool", readOnly: strictDisabled });

            if (fieldsConfig.length === 0)
                return;

            while (fieldsConfig.length)
                config.fieldRows.push({ fields: fieldsConfig.splice(0, 2)});

            return config;
        });
    },

    /**
     * Create form dialog
     */
    createFormDialog(name, alertify, width, onclose) {
        alertify.dialog(name, function() {
            return {
                main: function(content) {
                    this.setContent(content);
                },
                buid: function() {
                   delete this.elements.footer;
                },
                setup: function() {
                    return {
                        options: {
                            maximizable:false,
                            resizable:false,
                            padding:false,
                        }
                    };
                },
                hooks: {
                    onshow: function() {
                        this.elements.dialog.style.maxWidth = width ? width : "650px";
                    },
                    onclose: function() {
                        if (typeof onclose === "function")
                            onclose();
                    },
                }
            };
        });
    },
};