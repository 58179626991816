<project-document>
    <div class="project document-container">
        <div class="tabs-container">
            <tabs-bar name="projectDocument" tabs={ state.tabs } on-selected={ onTabSelected } initial-selection="Information"></tabs-bar>
            <div class="tab-content" if={ state.currentTab === "Information" }>
                <form-builder editable={ state.canEdit } config={ state.projectFormConfig } on-commit={ saveProject } model={ state.model }></form-builder>
                <div class="mui-row bottom-text">
                    <p>* Use this Id in eye-share to connect a company or group to a a set of licenses. Every license for this project will be fetched.</p>
                </div>
            </div>
            <div class="tab-content" if={ state.currentTab === "Parts" }>
                <div class="parts-grid">
                    <div class="view-col mui--divider-right">
                        <grid-js config={ state.licensePartsGridConfig } source={ fetchActive } on-selected={ licensePartSelected }></grid-js>
                    </div>
                    <div class="view-col menu-bar">
                        <div class="menu-item">
                            <button title="Create" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-plus" onclick={ createLicensePart }></button>
                        </div>
                        <div class="menu-item">
                            <button title="Delete" disabled={ !state.selectedLicensePart || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-minus" onclick={ deleteLicensePart }></button>
                        </div>
                    </div>
                </div>
                <div class="mui-divider form-divider"></div>
                <form-builder if={ state.selectedLicensePart && state.constraintsFormConfig } editable={ state.canEdit } config={ state.constraintsFormConfig } model={ state.selectedLicensePart } on-commit={ updatePart }></form-builder>
            </div>
            <div class="tab-content" if={ state.currentTab === "Evaluations" }>
                <div class="evaluations-grid">
                    <div class="view-col mui--divider-right">
                        <grid-js config={ state.evaluationsGridConfig } source={ fetchEvaluations }></grid-js>
                    </div>
                    <div class="view-col menu-bar">
                        <div class="menu-item">
                            <button title="ExportEvaluations" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-cloud-upload" onclick={ exportEvaluations }></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-content" if={ state.currentTab === "History" }>
                <div class="history-grid">
                    <div class="view-col">
                        <grid-js config={ state.historyGridConfig } source={ getHistory } on-selected={ historicVersionSelected }></grid-js>
                    </div>
                    <div if={ state.selectedHistoricVersion } class="view-col">
                        <div class="tabs-container">
                            <tabs-bar name="licenseHistory" tabs={ state.historicTabs } on-selected={ onHistoricTabSelected } initial-selection="$Information"></tabs-bar>
                            <div class="tab-content" if={ state.currentHistoricTab === "Information" }>
                                <form-builder if={ state.selectedHistoricVersion } read-only={ true } config={ state.historyFormConfig } model={ state.selectedHistoricVersion.item }></form-builder>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.canEdit = this.auth.canEdit();
                state.onModelChange = props.onModelChange;
                state.model = props.model;
                state.tabs = ["Information", "Parts", "Evaluations", "History"];
                state.historicTabs = ["Information"];
                state.currentHistoricTab = "Information"; // Issue with historic tab triggering a mounting loop due to the initial-selection mechanic. Hence the $ magic.
                state.licensePartsGridConfig = {
                    name: "project part",
                    height: "300px",
                    columns: [
                        { name: "Part", modelMap: "key", width: "200px" },
                        { name: "Product", modelMap: "product.key", width: "150px" },
                        { name: "Module", modelMap: "module", width: "125px" },
                        { name: "Type", modelMap: "type", type: "licenseType", width: "150px" },
                        { name: "Constraints", modelMap: "constraintsRenderValue", width: "200px" },
                        { name: "Description", modelMap: "description", width: "200px" },
                    ]
                };
                state.evaluationsGridConfig = {
                    name: "project evaluation",
                    height: "300px",
                    columns: [
                        { name: "Rule", modelMap: "rule.key", width: "200px" },
                        { name: "Status", modelMap: "status", width: "75px" },
                        { name: "Description", modelMap: "description", width: "200px" },
                        { name: "Module", modelMap: "module", width: "125px" },
                        { name: "Result", modelMap: "result", width: "100px" },
                        { name: "Constraints", modelMap: "constraintsRenderValue", width: "200px" },
                        { name: "Evaluated", modelMap: "evaluationDate", type: "date", width: "150px" },
                        { name: "Expires", modelMap: "expires", type: "date", width: "150px" },
                    ]
                };
                state.historyGridConfig = {
                    name: "project history",
                    height: "600px",
                    search: false,
                    sort: false,
                    columns: [
                        { name: "Version", modelMap: "version", width: "50px" },
                        { name: "Created", modelMap: "created", width: "175px", type: "date" },
                    ]
                };
                state.projectFormConfig = {
                    name: "project",
                    fieldRows: [
                        {
                            fields: [
                                { label: "Customer number", modelMap: "customerNumber", type: "text", readOnly: true },
                                { label: "Project number", modelMap: "key", type: "text", readOnly: true },
                            ]
                        },
                        {
                            fields: [
                                { label: "Id*", modelMap: "id", type: "text", readOnly: true },
                                { label: "Version", modelMap: "version", type: "text", readOnly: true },
                            ]
                        },
                        {
                            fields: [
                                { label: "Status", modelMap: "status", type: "text" },
                                { label: "Operation", modelMap: "operation", type: "text" },
                            ]
                        },
                        {
                            fields: [
                                { label: "Name", modelMap: "name", type: "text" },
                            ]
                        },
                        {
                            fields: [
                                { label: "Note", modelMap: "note", type: "textarea" },
                            ]
                        }
                    ]
                };
                state.historyFormConfig = {
                    name: "project history",
                    fieldRows: state.projectFormConfig.fieldRows,
                };
            },

            onTabSelected(selectedTab) {
                this.update({ currentTab: selectedTab });
            },

            onHistoricTabSelected(selectedTab) {
                this.update({ currentHistoricTab: selectedTab });
            },

            onBeforeUpdate(props, state) {
                if (props.model && (props.model.id !== state.model.id || props.model.version !== state.model.version)) {
                    state.model = props.model;
                    state.selectedHistoricVersion = state.selectedLicensePart = null;
                    state.currentHistoricTab = "Information";
                    this.notifyPartsGridRefresh();
                    this.notifyEvaluationsGridRefresh();
                    this.notifyHistoryGridRefresh();
                }
            },

            saveProject(model) {
                var uri = this.apiUrls.projects + "/" + model.id;
                return this.api.put(uri, model).then(() => this.state.onModelChange(model.id));
            },

            updatePart(part) {
                var uri = `${this.apiUrls.licenses}/${part.license.id}/parts/${part.id}`;

                this.api.put(uri, part).then(() => {
                    this.notifyPartsGridRefresh(part.id);
                });
            },

            licensePartSelected(licensePart) {
                if (!licensePart)
                    return;

                this.api.get(`${this.apiUrls.licenses}/strictRuleEnforcement/disabled/${this.state.model.key}`).then(strictDisabled => {
                    this.shared.buildConstraintsFormConfig(licensePart, this.api, this.apiUrls, strictDisabled).then(config => {
                        this.update({ selectedLicensePart: licensePart, constraintsFormConfig: config });
                    });
                });
            },

            getHistory() {
                return this.api.get(`${this.apiUrls.projects}/${this.state.model.id}/history`);
            },

            historicVersionSelected(historicVersion) {
                this.update({ selectedHistoricVersion: historicVersion });
            },

            fetchActive() {
                return this.api.get(`${this.apiUrls.licenses}/project/${this.state.model.id}/parts`);
            },

            fetchEvaluations() {
                var url = this.apiUrls.licenses + "/evaluations/" + this.state.model.id;
                return this.api.get(url);
            },

            exportEvaluations() {
                this.api.put(`${this.apiUrls.serviceNow}/export/evaluations/${this.state.model.id}`).then(() => {
                    this.alertify.notify("Evaluations for this project is exported to ServiceNow.");
                });
            },

            notifyPartsGridRefresh(id) {
                var selectedId = id ? id : this.state.selectedLicensePart ? this.state.selectedLicensePart.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.licensePartsGridConfig.name, selectedId);
            },

            notifyEvaluationsGridRefresh(id) {
                var selectedId = id ? id : this.state.selectedLicensePart ? this.state.model.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.evaluationsGridConfig.name, selectedId);
            },

            notifyHistoryGridRefresh() {
                var selectedId = this.state.selectedHistoricVersion ? this.state.selectedHistoricVersion.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.historyGridConfig.name, selectedId);
            },

            formatMoney(value) {
                return this.accounting.formatMoney(value, {
                        symbol : "",
                        format: "%v",
                        precision : this.formats.decimalPlaces,
                        thousand: this.formats.groupSeparator,
                        decimal : this.formats.decimalSeparator
                    });
            },

            createLicensePart() {
                var form = document.createElement("new-project-part");
                this.alertify.newProjectPartDialog || this.shared.createFormDialog('newProjectPartDialog', this.alertify, null, () => this.riot.unmount("new-project-part"));

                this.riot.mount(form, { onCreate: this.notifyPartsGridRefresh, customerProject: this.state.model, dialog: this.alertify.newProjectPartDialog });
                this.alertify.newProjectPartDialog(form).set({ title: "Add license part" });
            },

            deleteLicensePart() {
                var licensePart = this.state.selectedLicensePart;
                if (!licensePart)
                    return;

                var uri = `${this.apiUrls.licenses}/${licensePart.license.id}/parts/${licensePart.id}`;
                this.api.delete(uri).then(() => { this.notifyPartsGridRefresh(this.state.model.id); });
            }
        }
    </script>
</project-document>