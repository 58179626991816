<new-license-part>
    <div class="form-dialog">
        <form class="mui-form">
            <div class="mui-row">
                <div class="mui-col-md-12">
                    <typeahead name="Part" source={ getLicenseComponents } on-commit={ setComponent } model-value={ state.model.component } dropdown-parent={ ".ajs-modal" }></typeahead>
                </div>
            </div>
            <div id="newLicensePartConstraintsForm"></div>
            <div class="mui-row button-row">
               <button type="button" onclick={ create } class="mui-btn mui-btn--raised">Create</button>
               <button type="button" onclick={ closeDialog } class="mui-btn mui-btn--raised">Cancel</button>
            </div>
        </form>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.license = props.license;
                state.onCreate = props.onCreate;
                state.dialog = props.dialog;
                state.model = props.licensePart ? props.licensePart : {};
            },

            getLicenseComponents() {
                var license = this.state.license;
                var uri = `${this.apiUrls.lookup}/products/${license.product.id}/components?productVersion=${license.productVersion}`;
                return this.api.get(uri).then(components => components.filter(c => !license.parts.some(p => p.id == c.id)));
            },

            updateModel(model) {
                this.state.model = model;
            },

            setComponent(value) {
                if (!value)
                    return;

                var me = this;
                var licensePart = value;
                var projectKey = me.state.license.project.key;
                me.api.get(`${me.apiUrls.licenses}/strictRuleEnforcement/disabled/${projectKey}`).then(strictDisabled => {
                    if (strictDisabled) {
                        licensePart.strictRuleEnforcement = false;
                        buildConstraintsAndUpdateModel(strictDisabled);
                    }
                    else {
                        me.api.get(`${me.apiUrls.licenses}/strictRuleEnforcement/default`).then(defaultValue => {
                            licensePart.strictRuleEnforcement = defaultValue;
                            buildConstraintsAndUpdateModel();
                        });
                    }
                });

                function buildConstraintsAndUpdateModel(strictDisabled) {
                    me.riot.unmount('#newLicensePartConstraintsForm', true);
                    me.api.get(`${me.apiUrls.lookup}/components/${value.id}/type`).then(type => {
                        licensePart.type = type;
                        me.shared.buildConstraintsFormConfig(licensePart, me.api, me.apiUrls, strictDisabled).then(config => {
                            if (config) {
                                me.state.partConstraintsForm = me.riot.mount('#newLicensePartConstraintsForm', {
                                    continuousCommit: true,
                                    config: config,
                                    model: licensePart,
                                    onCommit: me.updateModel
                                }, 'form-builder');
                            }
                            me.update({ model: licensePart });
                        });
                    });
                }
            },

            create() {
                var uri = `${this.apiUrls.licenses}/${this.state.license.id}/parts`;

                this.api.put(uri, this.state.model).then((id) => {
                    if (id !== this.guidEmpty) {
                        if (this.state.onCreate)
                            this.state.onCreate(this.state.license.id);

                        this.alertify.notify("Part added.");
                        this.closeDialog();
                    }
                    else {
                        this.alertify.notify("Could not add part.");
                    }
                });
            },

            closeDialog() {
                if (this.state.dialog)
                    this.state.dialog().close();
            }
        }
    </script>
</new-license-part>