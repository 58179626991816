function getDefaultHeaders(token) {
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    return headers;
}

function handleResponse(response) {
    var contentType = response.headers.get('Content-Type');

    if (!response.ok) throw new Error(`Server returned ${response.status}`);
    else if (contentType === null) return $.when();
    else if (contentType.indexOf('application/json') > -1) return response.json();
    else if (contentType.indexOf('text/plain') > -1) return response.text();
    else throw new Error("Unsupported response content-type: " + contentType);
}

export default {
    /**
     * Init api instance
     */
    init(auth, apiUrls) {
        this.auth = auth;
        this.apiUrls = apiUrls;
    },

    /**
     * GET fetch with the given url.
     * @return {promise} Promise with GET result.
     */
    get(url) {
        return this.auth.acquireToken().then(token => {
            var headers = getDefaultHeaders(token);
            var options = {
                    method: "GET",
                    headers: headers
            };

            return fetch(url, options).then(handleResponse).catch(error => console.error('Error during GET.', error));
        });
    },

    /**
     * POST fetch with the given url.
     * @return {promise} Promise with POST result.
     */
    post(url, payload) {
        return this.auth.acquireToken().then(token => {
            var headers = getDefaultHeaders(token);
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
            var body = JSON.stringify(payload);
            var options = {
                    method: "POST",
                    headers: headers,
                    body: body
            };

            return fetch(url, options).then(handleResponse).catch(error => console.error('Error during POST.', error));
        });
    },

    /**
     * PUT fetch with the given url.
     * @return {promise} Promise with POST result.
     */
    put(url, payload) {
        return this.auth.acquireToken().then(token => {
            var headers = getDefaultHeaders(token);
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
            var body = JSON.stringify(payload);
            var options = {
                    method: "PUT",
                    headers: headers,
                    body: body
            };

            return fetch(url, options).then(handleResponse).catch(error => console.error('Error during POST.', error));
        });
    },

    /**
     * DELETE fetch with the given url.
     * @return {promise} Promise with DELETE result.
     */
    delete(url) {
        return this.auth.acquireToken().then(token => {
            var headers = getDefaultHeaders(token);
            var options = {
                    method: "DELETE",
                    headers: headers
            };

            return fetch(url, options).then(handleResponse).catch(error => console.error('Error during DELETE.', error));
        });
    },

    /**
     * download POST fetch with the given url.
     * @return {promise} Promise with blob result.
     */
     download(url, payload, fileName) {
        return this.auth.acquireToken().then(token => {
            var headers = getDefaultHeaders(token);
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
            var body = JSON.stringify(payload);
            var options = {
                    method: "POST",
                    headers: headers,
                    body: body
            };

            return fetch(url, options).then(response => {
                response.blob().then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    var extension = ".txt";
                    if (blob.type.indexOf('application/json') > -1)
                        extension = ".json";
                    else if (blob.type.indexOf('text/csv') > -1)
                        extension = ".csv";
                    a.download = fileName + extension;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
            }).catch(error => console.error('Error during POST(Download).', error));
        });
    },

    /**
     * Do a get against lookup controller with the given path.
     * @return {promise} Promise with GET result.
     */
    lookup(path) {
        return this.get(`${this.apiUrls.lookup}/${path}`);
    }
};