<licenses-view>
    <div class="view-licenses">
        <div class="view-grid">
            <div class="view-col menu-bar mui--divider-right">
                <div class="menu-item">
                    <button title="Create" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-plus" onclick={ newLicense }></button>
                </div>
                <div class="menu-item">
                    <button title="Delete" disabled={ !state.selectedLicense || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-minus" onclick={ deleteLicense }></button>
                </div>
                <div if={ !showSuspend() } class="menu-item">
                    <button title="Resume" disabled={ !state.selectedLicense || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-play" onclick={ resumeLicense }></button>
                </div>
                <div if={ showSuspend() } class="menu-item">
                    <button title="Suspend" disabled={ !state.selectedLicense || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-pause" onclick={ suspendLicense }></button>
                </div>
            </div>
            <div class="view-col mui--divider-right">
                <grid-js config={ state.licenseGridConfig } source={ fetchLicenseDto } on-selected={ licenseDtoSelected }></grid-js>
            </div>
            <div class="view-col">
                <license-document if={ state.selectedLicense } model={ state.selectedLicense } on-change={ notifyGridRefresh }></license-document>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                this.state.canEdit = this.auth.canEdit();
                this.state.licenseGridConfig = {
                    name: "license",
                    columns: [
                        { id: "Suspended", modelMap: "suspended", width: "15px", customFormatter: this.suspendedFormatter, title: "Suspended" },
                        { name: "Project", modelMap: "project.displayValue", width: "200px" },
                        { name: "Product", modelMap: "product.key", width: "250px" },
                        { name: "Description", modelMap: "description", width: "200px" },
                    ]
                };
            },

            suspendedFormatter(value) {
                return value ? this.html('<div class="zmdi zmdi-pause-circle red"></div>') : "";
            },

            fetchLicenseDto() {
                return this.api.get(`${this.apiUrls.licenses}/dto`);
            },

            licenseDtoSelected(licenseDto) {
                if (licenseDto) {
                    this.api.get(`${this.apiUrls.licenses}/${licenseDto.id}`).then(license => {
                        this.update({ "selectedLicense": license });
                    });
                }
            },

            newLicense() {
                this.alertify.newLicenseGroupDialog || this.shared.createFormDialog('newLicenseGroupDialog', this.alertify, null, () => this.riot.unmount("new-license"));
                var form = document.createElement("new-license");
                this.riot.mount(form, { onCreate: this.notifyGridRefresh, dialog: this.alertify.newLicenseGroupDialog });
                this.alertify.newLicenseGroupDialog(form).set({ title: "Create license" });
            },

            deleteLicense() {
                var me = this;
                var license = this.state.selectedLicense;
                if (!license)
                    return;

                this.alertify.confirm("Delete license: " + license.key + "?", function() {
                    var uri = me.apiUrls.licenses + "/" + license.id;
                    me.api.delete(uri).then(() => {
                        delete me.state.selectedLicenseGroup;
                        me.notifyGridRefresh();
                    });
                });
            },

            suspendLicense() {
                var me = this;
                var license = this.state.selectedLicense;
                if (!license)
                    return;

                this.alertify.confirm("Suspend license: " + license.key + "?", function() {
                    me.api.put(`${me.apiUrls.licenses}/${license.id}/suspend`).then(() => {
                        me.alertify.notify("License suspended.");
                        me.notifyGridRefresh();
                    });
                });
            },

            resumeLicense() {
                var license = this.state.selectedLicense;
                if (!license)
                    return;

                this.api.put(`${this.apiUrls.licenses}/${license.id}/resume`).then(() => {
                    this.alertify.notify("License resumed.");
                    this.notifyGridRefresh();
                });
            },

            showSuspend() {
                return !this.state.selectedLicense || !this.state.selectedLicense.suspended;
            },

            notifyGridRefresh(id) {
                this.eventEmitter.emit('grid_refreshSource', this.state.licenseGridConfig.name, id);
            },
        }
    </script>
</licenses-view>