<grid-js>
    <div class="grid-root" id={ state.gridId }></div>

    <script>
        var dataPromise;
        export default {
            onBeforeMount(props, state) {
                state.onSelected = props.onSelected;
                state.config = this.modifyConfig(props.config);
                state.gridId = _.camelCase(props.config.name) + "_grid";
                state.source = props.source;
                this.eventEmitter.on('grid_refreshSource', this.refreshSource);
                dataPromise = $.when();
                if (!props.lazySource)
                    this.fetchData();

                this.accounting.settings.currency = {
                    symbol : "",
                    format: "%v",
                    precision : this.formats.decimalPlaces,
		            thousand: this.formats.groupSeparator,
		            decimal : this.formats.decimalSeparator
                };
                this.accounting.settings.number = {
                    precision : this.formats.decimalPlaces,
		            thousand: this.formats.groupSeparator,
		            decimal : this.formats.decimalSeparator
                }
            },

            onMounted(props, state) {
                this.initGridJS();
            },

            onUnmounted(props, state) {
                this.eventEmitter.off('grid_refreshSource', this.refreshSource)
            },

            initGridJS() {
                dataPromise.then(() => {
                    var state = this.state;
                    var config = this.state.config;
                    this.state.grid = new this.gridjs({
                        autoWidth: false,
                        fixedHeader: true,
                        search: _.isBoolean(config.search) ? config.search : true,
                        sort: _.isBoolean(config.sort) ? config.sort : true,
                        height: config.height ? config.height : null,
                        columns: state.config.columns.map(columnInfo => columnInfo),
                        data: state.data ? state.data : [],
                    }).render(document.getElementById(state.gridId));

                    this.state.grid.on('rowClick', this.selectItem);
                    this.setSelection();
                });
            },

            refreshSource(gridName, selectId) {
                if (gridName === this.state.config.name)
                    this.fetchData(selectId).then(() => {
                        var state = this.state;
                        var searchKeyword = document.getElementById(this.state.gridId).getElementsByClassName("gridjs-search-input")[0].value;
                        var config = { data: state.data ? state.data : [] };
                        if (state.grid.config.search && searchKeyword)
                            config.search = { keyword: searchKeyword };

                        this.state.grid = state.grid.updateConfig(config).forceRender();
                        setTimeout(() => this.setSelection(selectId));
                    });
            },

            setSelection(selectId) {
                if (selectId !== false) {
                    var itemById = (id) => {
                        var items = this.state.grid.config.data.filter(item => item.id == id);
                        if (items.length > 0)
                            return items[0];

                        return;
                    };

                    if (selectId)
                        this.state.selectedItem = itemById(selectId);
                    else if (this.state.selectedItem)
                        this.state.selectedItem = itemById(this.state.selectedItem.id);

                    if (this.state.selectedItem)
                        this.selectElementFromItem(this.state.selectedItem);

                }
                else {
                    this.state.selectedItem = null;
                }

                if (this.state.onSelected)
                    this.state.onSelected(this.state.selectedItem);
            },

            modifyConfig(config) {
                if (config.columns[0].name !== "id") {
                    config.columns.unshift({
                        name: "Id",
                        hidden: true,
                        modelMap: "id",
                    });
                }

                config.columns.forEach(ci => {
                    ci.selector = (row) => _.get(row, ci.modelMap);
                    ci.formatter = (cell) => ci.customFormatter ? ci.customFormatter(cell) : this.getDisplayValue(cell, ci.type);
                    ci.attributes = (cell, row, colum) => {
                        return {
                            "data-id": row.cells[0].data,
                            "title": ci.title ? ci.title : this.getDisplayValue(cell, ci.type)
                        }
                    };
                });

                return config;
            },

            fetchData(selectId) {
                var source = this.state.source;
                if (typeof source === "function") {
                    dataPromise = source().then(data => {
                        this.state.data = data;
                    });
                    return dataPromise;
                }

                return $.when();
            },

            getDisplayValue(value, type) {
                if (type === "date" || type === "shortdate") {
                    var m = this.moment(value);
                    var format = type === "date" ? this.formats.date : this.formats.shortdate;
                    return value && m.isValid() ? m.format(format) : "";
                }
                if (type === "money") {
                    return this.accounting.formatMoney(value, {
                        symbol : "",
                        format: "%v",
                        precision : this.formats.decimalPlaces,
                        thousand: this.formats.groupSeparator,
                        decimal : this.formats.decimalSeparator
                    });
                }
                if (type === "number") {
                    return this.accounting.formatNumber(value, {
                        precision : this.formats.decimalPlaces,
                        thousand: this.formats.groupSeparator,
                        decimal : this.formats.decimalSeparator
                    });
                }
                if (type === "bool") {
                    return value ? "true" : "false";
                }
                if (type === "licenseType" && value && typeof value === "object")
                    return `${value.type} (${value.restrictionType})`;

                return value;
            },

            isItemSelected(item) {
                return this.state.selectedItem && this.state.selectedItem.id === item.id;
            },

            getItem(id, data) {
                var data = this.state.grid.config.data;
                var items = data.filter(function(item) { return item.id === id; });
                if (items.length > 0)
                    return items[0];

                return;
            },

            selectItem(e, row) {
                var id = row.cells[0].data;
                var item = this.getItem(id);
                if (this.isItemSelected(item))
                    return;

                this.selectElement(e.currentTarget);
                this.update({ selectedItem: item });

                if (this.state.onSelected)
                    this.state.onSelected(item);
            },

            selectElementFromItem(item) {
                var rowEl = $(`#${this.state.gridId} td[data-id=${item.id}]`)[0].parentElement;
                this.selectElement(rowEl);
            },

            selectElement(rowEl) {
                var existingSelected = $(`#${this.state.gridId} tbody > tr.selected`);
                existingSelected.each((i, el) => {
                    el.classList.remove("selected");
                });
                rowEl.classList.add("selected");
            }
        }
    </script>
</grid-js>