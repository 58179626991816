<projects-view>
    <div class="view-projects">
        <div class="view-grid">
            <div class="view-col menu-bar mui--divider-right">
                <div class="menu-item">
                    <button title="Create" disabled={ !state.canEdit} class="mui-btn mui-btn--fab primary zmdi zmdi-plus" onclick={ newProject }></button>
                </div>
                <div class="menu-item">
                    <button title="Delete" disabled={ !state.selectedProject || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-minus" onclick={ deleteProject }></button>
                </div>
                <div class="menu-item">
                    <button title="Replicate" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-cloud-download" onclick={ replicate }></button>
                </div>
                <div class="menu-item">
                    <button title="ExportEvaluations" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-cloud-upload" onclick={ exportEvaluations }></button>
                </div>
                <div class="menu-item">
                    <button title="Reports" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-file-text" onclick={ downloadReport }></button>
                </div>
            </div>
            <div class="view-col mui--divider-right">
                <grid-js config={ state.projectsGridConfig } source={ fetchProjects } on-selected={ projectSelected }></grid-js>
            </div>
            <div class="view-col">
                <project-document if={ state.selectedProject } model={ state.selectedProject } on-model-change={ notifyGridRefresh }></project-document>
            </div>
        </div>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                this.state.canEdit = this.auth.canEdit();
                this.state.projectsGridConfig = {
                    name: "project",
                    columns: [
                        { name: "Customer number", modelMap: "customerNumber", width: "150px" },
                        { name: "Project number", modelMap: "key", width: "140px" },
                        { name: "Name", modelMap: "name", width: "200px" },
                        { name: "Status", modelMap: "status", width: "200px" },
                        { name: "Operation", modelMap: "operation", width: "200px" },
                    ]
                };
            },

            fetchProjects() {
                return this.api.get(this.apiUrls.projects);
            },

            projectSelected(project) {
                this.update({ selectedProject: project });
            },

            replicate() {
                this.api.put(`${this.apiUrls.serviceNow}/replicate`).then(() => {
                    this.notifyGridRefresh();
                    this.alertify.notify("Projects replicated from ServiceNow.");
                });
            },

            exportEvaluations() {
                this.api.put(`${this.apiUrls.serviceNow}/export/evaluations`).then(() => {
                    this.alertify.notify("Evaluations exported to ServiceNow.");
                });
            },

            downloadReport() {
                var form = document.createElement("reports");
                this.alertify.downloadReportDialog || this.shared.createFormDialog('downloadReportDialog', this.alertify, null, () => this.riot.unmount("reports"));
                this.riot.mount(form, { dialog: this.alertify.downloadReportDialog });
                this.alertify.downloadReportDialog(form).set({ title: "Reports" });
            },

            notifyGridRefresh(id) {
                this.eventEmitter.emit('grid_refreshSource', this.state.projectsGridConfig.name, id);
            },

            deleteProject() {
                var project = this.state.selectedProject;
                this.api.get(`${this.apiUrls.licenses}/project/${project.id}/any`).then(anyLicenses => {
                    if (anyLicenses) {
                        this.alertify.notify("There are licenses referencing this project. Can not delete.");
                        return;
                    }

                    var me = this;
                    this.alertify.confirm(`Delete project: ${project.key} - ${project.name} ?`, function() {
                        var uri = me.apiUrls.projects + "/" + project.id;
                        me.api.delete(uri).then(() => {
                            delete me.state.selectedProject;
                            me.notifyGridRefresh();
                            me.alertify.notify("Project deleted.");
                        });
                    });
                });
            },

            newProject() {
                var form = document.createElement("new-project");
                this.alertify.newProjectDialog || this.shared.createFormDialog('newProjectDialog', this.alertify, null, () => this.riot.unmount("new-project"));
                this.riot.mount(form, { onCreate: this.notifyGridRefresh, dialog: this.alertify.newProjectDialog });
                this.alertify.newProjectDialog(form).set({ title: "Create project" });
            },
        }
    </script>
</projects-view>