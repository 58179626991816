<module-checkbox>
    <div class="mui-checkbox">
        <label>
            <input type="checkbox" disabled={ state.disabled } onchange={ commit } checked={ state.value }>
            { state.label }
        </label>
    </div>

    <script>
        export default {
            onBeforeMount(props, state) {
                state.label = props.label;
                state.disabled = props.disabled;
                state.modelMap = props.modelMap;
                state.onCommit = props.onCommit;
                state.value = props.modelValue;
            },

            onBeforeUpdate(props, state) {
                if (props.id !== state.id) {
                    state.label = props.label;
                    state.modelMap = props.modelMap;
                    state.onCommit = props.onCommit;
                }

                state.value = props.modelValue;
                state.disabled = props.disabled;
            },

            commit(e) {
                var value = e.target.checked;
                if (this.state.onCommit)
                    this.state.onCommit(value, this.state.modelMap);
            }
        }
    </script>
</module-checkbox>