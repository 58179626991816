<license-document>
    <div class="license-document">
        <div class="tabs-container">
            <tabs-bar name="licenseForm" tabs={ state.tabs } on-selected={ onTabSelected } initial-selection="Information"></tabs-bar>
            <div class="tab-content"  if={ state.currentTab === "Information" }>
                <form-builder editable={ state.canEdit } config={ state.licenseFormConfig } model={ state.model } on-commit={ saveLicense }></form-builder>
            </div>
            <div class="tab-content" if={ state.currentTab === "Parts" }>
                <div class="parts-grid">
                    <div class="view-col mui--divider-right">
                        <grid-js config={ state.licensePartsGridConfig } source={ getLicenseParts } on-selected={ licensePartSelected }></grid-js>
                    </div>
                    <div class="view-col menu-bar">
                        <div class="menu-item">
                            <button title="Create" disabled={ !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-plus" onclick={ createLicensePart }></button>
                        </div>
                        <div class="menu-item">
                            <button title="Delete" disabled={ !state.selectedLicensePart || !state.canEdit } class="mui-btn mui-btn--fab primary zmdi zmdi-minus" onclick={ deleteLicensePart }></button>
                        </div>
                    </div>
                </div>
                <div class="mui-divider form-divider"></div>
                <form-builder if={ state.selectedLicensePart && state.constraintsFormConfig } editable={ state.canEdit } config={ state.constraintsFormConfig } on-commit={ updatePart } model={ state.selectedLicensePart }></form-builder>
            </div>
            <div class="tab-content"  if={ state.currentTab === "History" }>
                <div class="history-grid">
                    <div class="view-col">
                        <grid-js config={ state.historyGridConfig } source={ getHistory } on-selected={ historicVersionSelected }></grid-js>
                    </div>
                    <div if={ state.selectedHistoricVersion } class="view-col">
                        <div class="tabs-container">
                            <tabs-bar name="licenseHistory" tabs={ state.historicTabs } on-selected={ onHistoricTabSelected } initial-selection="$Information"></tabs-bar>
                            <div class="tab-content" if={ state.currentHistoricTab === "Information" }>
                                <form-builder read-only={ true } config={ state.historyFormConfig } model={ state.selectedHistoricVersion.item }></form-builder>
                            </div>
                            <div class="tab-content" if={ state.currentHistoricTab === "Parts" }>
                                <grid-js config={ state.historyPartsGridConfig } source={ getHistoricLicenseParts } on-selected={ historicLicensePartSelected }></grid-js>
                                <div class="mui-divider form-divider"></div>
                                <form-builder if={ state.selectedHistoricLicensePart && state.constraintsFormConfig } read-only={ true } config={ state.constraintsFormConfig } model={ state.selectedHistoricLicensePart }></form-builder>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

     <script>
        export default {
            onBeforeMount(props, state) {
                state.canEdit = this.auth.canEdit();
                state.model = props.model;
                state.onChange = props.onChange;

                // tabs
                state.tabs = ["Information", "Parts", "History"];
                state.historicTabs = ["Information", "Parts"];
                state.currentHistoricTab = "Information"; // Issue with historic tab triggering a mounting loop due to the initial-selection mechanic. Hence the $ magic.

                // grids
                state.licensePartsGridConfig = {
                    name: "license parts",
                    height: "300px",
                    columns: [
                        { name: "Name", modelMap: "key", width: "200px" },
                        { name: "Type", modelMap: "type", type: "licenseType", width: "150px" },
                        { name: "Description", modelMap: "description", width: "250px" },
                    ]
                };
                state.historyPartsGridConfig = {
                    name: "license parts history",
                    height: "300px",
                    columns: state.licensePartsGridConfig.columns
                };
                state.historyGridConfig = {
                    name: "license history",
                    height: "600px",
                    search: false,
                    sort: false,
                    columns: [
                        { name: "Version", modelMap: "version", width: "50px" },
                        { name: "Created", modelMap: "created", width: "175px", type: "date" },
                    ]
                };

                // forms
                state.licenseFormConfig = {
                    name: "license",
                    fieldRows: [
                        {
                            fields: [
                                { label: "Customer", modelMap: "project.key", type: "text", readOnly: true },
                                { label: "Product", modelMap: "product.key", type: "text", readOnly: true },
                            ]
                        },
                        {
                            fields: [
                                { label: "Version", modelMap: "version", type: "text", readOnly: true },
                                { label: "Product version", modelMap: "productVersion", type: "text", readOnly: true }
                            ]
                        },
                        {
                            fields: [
                                { label: "Installations", modelMap: "tenant.id", type: "text", readOnly: true },
                                { label: "Clients", modelMap: "tenant.client", type: "text", readOnly: true }
                            ]
                        },
                        {
                            fields: [
                                { label: "Description", modelMap: "description", type: "text" }
                            ]
                        },
                        {
                            fields: [
                                { label: "Valid from", modelMap: "validFrom", type: "date" },
                                { label: "Valid to", modelMap: "validTo", type: "date" }
                            ]
                        },
                        {
                            fields: [
                                { label: "Note", modelMap: "note", type: "textarea" },
                            ]
                        }
                    ]
                };
                state.historyFormConfig = {
                    name: "license history",
                    fieldRows: state.licenseFormConfig.fieldRows,
                };
            },

            onTabSelected(selectedTab) {
                this.update({ currentTab: selectedTab });
            },

            onHistoricTabSelected(selectedTab) {
                this.update({ currentHistoricTab: selectedTab });
            },

            onBeforeUpdate(props, state) {
                if (props.model && (props.model.id !== state.model.id || props.model.version !== state.model.version)) {
                    state.model = props.model;
                    state.selectedLicensePart = state.selectedHistoricVersion = state.selectedHistoricLicensePart = null;
                    state.currentHistoricTab = "Information";
                    this.notifyPartsGridRefresh();
                    this.notifyHistoryGridRefresh();
                }
            },

            saveLicense(model) {
                var uri = this.apiUrls.licenses;
                return this.api.put(uri, model).then(() => this.state.onChange(model.id));
            },

            getLicenseParts() {
                return $.when(this.state.model.parts);
            },

            getHistoricLicenseParts() {
                return $.when(this.state.selectedHistoricVersion.item.parts);
            },

            getHistory() {
                return this.api.get(`${this.apiUrls.licenses}/${this.state.model.id}/history`);
            },

            historicVersionSelected(historicVersion) {
                this.update({ selectedHistoricVersion: historicVersion });
                this.notifyHistoryPartsGridRefresh();
            },

            licensePartSelected(licensePart) {
                if (!licensePart)
                    return;

                this.api.get(`${this.apiUrls.licenses}/strictRuleEnforcement/disabled/${this.state.model.project.key}`).then(strictDisabled => {
                    this.shared.buildConstraintsFormConfig(licensePart, this.api, this.apiUrls, strictDisabled).then(config => {
                        this.update({ selectedLicensePart: licensePart, constraintsFormConfig: config });
                    });
                });
            },

            historicLicensePartSelected(licensePart) {
                if (!licensePart)
                    return;

                if (!licensePart.version)
                    licensePart.version = this.state.selectedHistoricVersion.version;

                this.shared.buildConstraintsFormConfig(licensePart, this.api, this.apiUrls).then(config => {
                    this.update({ selectedHistoricLicensePart: licensePart, constraintsFormConfig: config });
                });
            },

            updatePart(part) {
                var uri = `${this.apiUrls.licenses}/${this.state.model.id}/parts/${part.id}`;

                this.api.put(uri, part).then(() => {
                    this.state.onChange();
                });
            },

            createLicensePart() {
                var form = document.createElement("new-license-part");
                this.alertify.newLicensePartDialog || this.shared.createFormDialog('newLicensePartDialog', this.alertify, null, () => this.riot.unmount("new-license-part"));

                this.riot.mount(form, { onCreate: this.state.onChange, license: this.state.model, dialog: this.alertify.newLicensePartDialog });
                this.alertify.newLicensePartDialog(form).set({ title: "Add license part" });
            },

            deleteLicensePart() {
                var licensePart = this.state.selectedLicensePart;
                if (!licensePart)
                    return;

                var uri = `${this.apiUrls.licenses}/${this.state.model.id}/parts/${licensePart.id}`;
                this.api.delete(uri).then(() => { this.state.onChange(this.state.model.id); });
            },

            notifyPartsGridRefresh() {
                var selectedId = this.state.selectedLicensePart ? this.state.selectedLicensePart.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.licensePartsGridConfig.name, selectedId);
            },

            notifyHistoryGridRefresh() {
                var selectedId = this.state.selectedHistoricVersion ? this.state.selectedHistoricVersion.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.historyGridConfig.name, selectedId);
            },

            notifyHistoryPartsGridRefresh() {
                var selectedId = this.state.selectedHistoricLicensePart ? this.state.selectedHistoricLicensePart.id : undefined;
                this.eventEmitter.emit('grid_refreshSource', this.state.historyPartsGridConfig.name, selectedId);
            },
        }
    </script>
</license-document>